<template lang="pug">
.boxLayout.s-box.s-shadow--xl.s-padding--none.s-col-7.s-move--center.s-expand-phone.s-margin-y--l.s-margin-x
  .s-text-align--center.s-padding-y--l.s-padding-x
    .icon-calendar-check-fill.s-swatch-primary--text.s-text-size--7xl.s-margin-bottom--xxs
    .s-text-size--xl--heading Your Demo is successfully booked!
    //- a(href="/") Return to home page#[span.icon-arrow-right-s-line.s-margin-left--xxs]
  .s-hr-label.s-margin--none
    span.s-text-size--xs--caps--bold.s-swatch-primary--text Booking Details
  .s-flex-items--center-content--center.s-items.s-move--center.s-padding-y--s.s-padding-x
    .icon-mail-unread-line.s-swatch-primary--text.s-text-size--5xl
    .textSilver You will receive an invitation email with Google Meet credentials
</template>

<script>
/* eslint-disable no-undef */
export default {
  // You can specify layout component name here (default value is 'default')
  layout: 'full',
  mounted () {
    startBg()
  }
}
</script>
